<template>
  <div style="display:flex;justify-content:center">
    <b-img :src="require('@/assets/level_t.jpg')" fluid />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>
